@use 'sass:math';

//
// General
//
$transition-duration: 300ms !default;
$gutter: 1rem !default;

// Colors
$color-disabled-01: #f3f3f3;
$color-disabled-02: #999999;
$color-error: #f44336;
$color-hover-primary: #9aca3c;
$color-interactive-00: #aeea00;
$color-interactive-01: #79b700;
$color-interactive-03: #578400;
$color-warning: #F2B931;
$color-ui-00: #ffffff;
$color-ui-01: #fafafa;
$color-ui-02: #efefef;
$color-ui-03: #d9d9d9;
$color-ui-04: #949494;
$color-ui-05: #757575;
$color-ui-06: #40444c;
$color-icon-01: #ffffff;
$color-icon-02: #595959;
$color-hover-ui: #f3f3f3;
$color-text-01: #3a3a3a;
$color-focus-01: #c9e299;
$color-focus-error: #fdb7b1;
$color-overlay-01: rgba(17, 17, 19, 0.78);
$color-informational: #0055E6;
$color-danger: #CC0100;
$color-success: #148010;
$color-field-01: #ffffff;

//
// Widths
//
$sidebar-width: 15rem !default;
$compact-sidebar-width: 100px !default;
$collapsed-sidebar-width: 48px !default;
$non-collapsed-sidebar-width: 240px !default;
$sidebar-width-mobile: 18rem !default;

//
// Typography
//
$mat-font-family: Roboto, 'Helvetica Neue', sans-serif;
$font-size-base: 0.875rem !default;
$font-weight-base: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 600 !default;

$font-size-h1: 2.5rem !default;
$font-size-h2: 2rem !default;
$font-size-h3: 1.75rem !default;
$font-size-h4: 1.5rem !default;
$font-size-h5: 1.25rem !default;
$font-size-h6: 1rem !default;

$headings-margin-bottom: math.div(1rem, 2) !default;
$headings-font-family:   inherit !default;
$headings-font-weight:   400 !default;
$headings-line-height:   1.1 !default;
$headings-color:         inherit !default;

//
// Radius
//
$border-radius-base: 2px !default;
$border-radius-large: 2px !default;
$border-radius-small: 1px !default;

//
// Spacing
//
$spacer:   1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  xs: (
    x: math.div($spacer-x, 1.8),
    y: math.div($spacer-y, 1.8)
  ),
  1: (
    x: $spacer-x,
    y: $spacer-y
  ),
  2: (
    x: ($spacer-x * 1.5),
    y: ($spacer-y * 1.5)
  ),
  3: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  )
) !default;
$border-width: 1px !default;


// Media queries breakpoints
// --------------------------------------------------

// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 600px,
  // Medium screen / tablet
  md: 960px,
  // Large screen / desktop
  lg: 1280px,
  // Extra large screen / wide desktop
  xl: 1920px
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 940px,
  xl: 1140px
) !default;

// Theme
.cds-light-mode, :root {
  --color-focus-01: #c9e299 !important;
  --color-hover-primary: #9aca3c !important;
  --color-interactive-01: #79b700 !important;
}

.cds-dark-mode {
  --color-focus-01: #c9e299 !important;
  --color-hover-primary: #9aca3c !important;
  --color-interactive-01: #aeea00 !important;
}

.cds-dropdown .cds-button > .material-icons {
  font-size: 1.5rem;
}

cds-loading-spinner {
  --color-focus-01: #c9e299 !important;
  --color-hover-primary: #9aca3c !important;
  --color-interactive-01: #aeea00 !important;
}